// return the user data from the local storage
export const getUser = () => {
  const userStr = localStorage.getItem("user"); // Using localStorage instead of sessionStorage
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the local storage
export const getToken = () => {
  return localStorage.getItem("token") || null; // Using localStorage instead of sessionStorage
};

// remove the token and user from the local storage
export const removeUserSession = () => {
  localStorage.removeItem("token"); // Using localStorage instead of sessionStorage
  localStorage.removeItem("user"); // Using localStorage instead of sessionStorage
};

// set the token and user in the local storage
export const setUserSession = (token, user) => {
  localStorage.setItem("token", token); // Using localStorage instead of sessionStorage
  localStorage.setItem("user", JSON.stringify(user)); // Using localStorage instead of sessionStorage
};

// get the user role ID from the local storage
export const getUserRoleID = () => {
  const userStr = localStorage.getItem("user"); // Using localStorage instead of sessionStorage
  if (userStr) {
    const user = JSON.parse(userStr); // Parse the JSON string to an object
    return user.role_id; // Return the role_id
  }
}