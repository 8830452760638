import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row, Table, Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../components/Navbar";
import Footer from "../components/Footer";
import axios from "../utils/axios";
import moment from "moment";
import html2pdf from "html2pdf.js";
import Swal from "sweetalert2";

const PurchaseOrderByDate = () => {
  const { IDhotel, IDSupplier, dateEvent } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [dataMenu, setDataMenu] = useState([]);
  const [dataMenuPrev, setDataMenuPrev] = useState([]);
  const [prevPONumber, setPrevPONumber] = useState(null);
  const [dataComparation, setDataComparation] = useState([]);
  const [lastPONumber, setLastPONumber] = useState(null);
  const [newCostDescription, setNewCostDescription] = useState("");
  const [newCostAmount, setNewCostAmount] = useState(0);
  const [grandTotalPO, setGrandTotalPO] = useState(null);
  const [additionalCosts, setAdditionalCosts] = useState([]);
  const pdfContentRef = useRef(null);

  const formatToRupiah = (number) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(number);
  };

  const getDetailPObyDate = async () => {
    setLoading(true);
    try {
      const [poDataResponse, lastPOResponse] = await Promise.all([
        axios.get(`/api/detail/po/generate/${IDhotel}/${IDSupplier}/${dateEvent}`),
        axios.get(`/api/po/last/number`),
      ]);
      // console.log(poDataResponse.data);
      setData(poDataResponse.data);

       // Filter lastData to include only items where totalPax > 0
       const filteredMenuData = poDataResponse.data.menu.filter(item => item.totalPax > 0);
  
       // Update the queryResultMenuPO state with the filtered data
       setData({
         ...poDataResponse.data,
         menu: filteredMenuData,
       });

      // setDataMenu(poDataResponse.data.menu);
      setDataMenuPrev(poDataResponse.data.prevData);
      setDataComparation(poDataResponse.data.comparePax);
      setLastPONumber(lastPOResponse.data.last_po_number);
      setPrevPONumber(poDataResponse.data.prevPONo);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
    setLoading(false);
  };

  const calculateGrandTotal = (menuItems, additionalCosts) => {
    const menuTotal = menuItems.reduce((total, item) => total + item.price * item.totalPax, 0);
    const additionalCostsTotal = additionalCosts.reduce((total, cost) => total + cost.amount, 0);
    const grandTotal = menuTotal + additionalCostsTotal;
    setGrandTotalPO(grandTotal);
  };

  const generatePONumber = (date, hotelCode, lastNumber, hasComparation) => {
    const nextNumber = lastNumber ? parseInt(lastNumber.split("/").pop(), 10) : 1;
    const uniqueNumber = nextNumber.toString().padStart(4, "0");
    const dateFormatted = moment(date).format("YYYY/MM/DD");
    return hasComparation ? `${dateFormatted}/${hotelCode}/REV/${prevPONumber}/FB/${uniqueNumber}` : `${dateFormatted}/${hotelCode}/FB/${uniqueNumber}`;
  };

  const poNumber = data ? generatePONumber(dateEvent, data.hotel.code_hotel, lastPONumber, dataComparation.length > 0) : "";

  const handleTotalPaxChange = (index, newPax) => {
    const updatedData = { ...data };
    updatedData.menu[index].totalPax = newPax;
    setData(updatedData);
  };

  const handleAddCost = () => {
    setAdditionalCosts([...additionalCosts, { description: newCostDescription, amount: parseFloat(newCostAmount) }]);
    setNewCostDescription("");
    setNewCostAmount(0);
  };

  const handleDeleteCost = (index) => {
    const updatedCosts = additionalCosts.filter((_, costIndex) => costIndex !== index);
    setAdditionalCosts(updatedCosts);
  };

  const generatePDF = async () => {
    const confirmText = prevPONumber 
      ? `Are you sure to replace prev Purchase Order to ${poNumber}, and generate to PDF ?` 
      : `Do you want to generate PDF and upload Purchase Order with new number ${poNumber}?`;

    const result = await Swal.fire({
      title: 'Are you sure?',
      text: confirmText,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, generate it!',
      cancelButtonText: 'No, cancel!',
    });

    if (result.isConfirmed) {
      const element = pdfContentRef.current;
      const opt = {
        margin: [0.3, 0.1, 0.4, 0.1], // Adjusted margins
        filename: `POFB_${poNumber}.pdf`,
        image: { type: "jpeg", quality: 9 },
        html2canvas: { scale: 5 }, // Adjusted scale
        jsPDF: { unit: "in", format: "a4", orientation: "portrait", compressPDF: true }, // Added compressPDF
        pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      };

      try {
        const pdfBlob = await html2pdf().from(element).set(opt).output("blob");

        if (!(pdfBlob instanceof Blob)) {
          throw new Error("Failed to generate a valid Blob.");
        }

        const formData = new FormData();
        formData.append("file", pdfBlob, `PO_${poNumber}.pdf`);
        formData.append("PONo", poNumber);
        formData.append("IDhotel", IDhotel);
        formData.append("IDSupplier", IDSupplier);
        formData.append("dateEvent", dateEvent);
        formData.append("dataMenu", JSON.stringify(data.menu));
        formData.append("grandTotalPO", grandTotalPO);
        formData.append("totalCost", grandTotalPO); // Add the total cost

        const response = await axios.post("/api/save/pdf/po", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        
        setLoading(false);
        html2pdf().from(element).set(opt).save();
        console.log(response.data);

        // Delay for 6 seconds, then show Swal and redirect
        setTimeout(() => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
          }).then(() => {
            window.location.href = '/po/list';
          });
        }, 4000); // 6000 milliseconds = 6 seconds

      } catch (error) {
        console.error("Error generating or uploading PDF:", error);
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to generate or upload PDF. Please try again later.",
        });
      }
    }
  };

  useEffect(() => {
    getDetailPObyDate();
  }, [IDhotel, IDSupplier, dateEvent]);

  useEffect(() => {
    if (data) {
      calculateGrandTotal(data.menu, additionalCosts);
    }
  }, [data, additionalCosts]);

  return (
    <>
      <Header />
      <div style={{ marginLeft: "10px", marginRight: "10px", paddingLeft: "2%", paddingRight: "2%" }} ref={pdfContentRef}>
        {loading ? (
          <p>Loading Data Calculation...</p>
        ) : data ? (
          <>
            <Container fluid style={{ border: "1px solid #9e9e9e", borderRadius: "12px", paddingLeft: "1%", paddingRight: "1%", marginTop: "10px" }}>
              <Row className="mb-2 mt-4 text-center">
                <Col>
                  <h5>
                    <b>{data.hotel.nm_hotel}</b>
                  </h5>
                  <h6>PURCHASE ORDER</h6>
                  <p>{data.hotel.addr_hotel}</p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Table size="sm">
                    <tbody>
                      <tr>
                        <td>
                          <Table style={{ fontSize: "11px" }}>
                            <tbody>
                              <tr style={{ gridRowGap: "2px", borderColor: "transparent" }}>
                                <td>
                                  <strong>PO No:</strong>
                                </td>
                                <td>
                                  <b>{poNumber}</b>
                                </td>
                              </tr>
                              <tr style={{ height: "2px", borderColor: "transparent" }}>
                                <td>
                                  <strong>PO Created:</strong>
                                </td>
                                <td>{moment().format("LL")}</td>
                              </tr>
                              <tr style={{ height: "2px", borderColor: "transparent" }}>
                                <td>
                                  <strong>Expected Arrival:</strong>
                                </td>
                                <td>{moment(dateEvent).format("LL")}</td>
                              </tr>
                              <tr style={{ borderColor: "transparent" }}>
                                <td>
                                  <strong>Event Date:</strong>
                                </td>
                                <td>{moment(dateEvent).format("LL")}</td>
                              </tr>
                              <tr style={{ borderColor: "transparent" }}>
                                <td>
                                  <strong>Address:</strong>
                                </td>
                                <td>{data.hotel.addr_hotel}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                        <td>
                          <Table style={{ fontSize: "11px" }}>
                            <tbody>
                              <tr style={{ borderColor: "transparent" }}>
                                <td>
                                  <strong>To:</strong>
                                </td>
                                <td>{data.supplier.name_supplier}</td>
                              </tr>
                              <tr style={{ borderColor: "transparent" }}>
                                <td>
                                  <strong>Address:</strong>
                                </td>
                                <td>{data.supplier.add_supplier}</td>
                              </tr>
                              <tr style={{ borderColor: "transparent" }}>
                                <td>
                                  <strong>Attn:</strong>
                                </td>
                                <td>{data.supplier.name_supplier}</td>
                              </tr>
                              <tr style={{ borderColor: "transparent" }}>
                                <td>
                                  <strong>Phone:</strong>
                                </td>
                                <td>{data.supplier.phone_supplier}</td>
                              </tr>
                              <tr style={{ borderColor: "transparent" }}>
                                <td>
                                  <strong>Email:</strong>
                                </td>
                                <td>{data.supplier.email_supplier}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>

              {/* List Of Item */}
              <Row>
                <Col>
                  <Table size="sm" bordered>
                    <thead>
                      <tr style={{ textAlign: "justify" }}>
                        <th>SKU</th>
                        <th>Item</th>
                        <th>Menu</th>
                        <th style={{paddingLeft:"1.2%"}}>Qty</th>
                        <th>Unit</th>
                        <th>Price</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.menu.map((menuItem, index) => (
                        <tr key={index}>
                          <td>{`${menuItem.code}${menuItem.id_menu}${menuItem.group_id}`}</td>
                          <td>{menuItem.item_name}</td>
                          <td style={{ width: "90px" }}>{menuItem.group_name}</td>
                          <td style={{ width: "100px", textAlign: "center", fontSize: "9px"}}>
                            <Form.Control
                              type="number"
                              value={menuItem.totalPax}
                              onChange={(e) => handleTotalPaxChange(index, parseInt(e.target.value, 10))}
                              min="0"
                              style={{ backgroundColor: "transparent", width: "75px", border:"none", fontSize: "13px" }}
                            />
                          </td>
                          <td style={{ width: "20px",paddingRight:"1%" }}>{menuItem.unit}</td>
                          <td style={{ width: "100px" }}>{formatToRupiah(menuItem.price)}</td>
                          <td style={{ width: "100px" }}>{formatToRupiah(menuItem.price * menuItem.totalPax)}</td>
                        </tr>
                      ))}
                      {additionalCosts.map((cost, index) => (
                        <tr key={`cost-${index}`}>
                          <td>
                            <i className="bi bi-trash3-fill" onClick={() => handleDeleteCost(index)}></i>
                          </td>
                          <td colSpan="4">{cost.description}</td>
                          <td>{formatToRupiah(cost.amount)}</td>
                          <td>
                            {formatToRupiah(cost.amount)}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="6" className="text-right">
                          <strong>Grand Total</strong>
                        </td>
                        <td><b>{formatToRupiah(grandTotalPO)}</b></td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
               {/* End List Of Item */}

              <Row className="mt-1">
                <Col>
                  <p className="mb-2">
                    <span style={{ fontWeight: "bold" }}>Payment</span>: 14 day after received invoice
                  </p>
                  <p style={{ textAlign: "justify",fontSize:"10px" }}>
                    <strong>Anti Bribery / Anti Corruption undertaking</strong>
                    <br />
                    Anti-Bribery / Anti-Corruption Undertaking Supplier Agrees and Undertakes that in connection with
                    Purchase Order, it will comply with all applicable government laws, rules, and regulations of The
                    Anti-Bribery / Anti-Corruption Policy. Supplier has not and will not directly or indirectly offer to
                    or pay any money or anything of value to hotel’s employees or any other person in connection with the
                    delivery of the product or the service performed under this Purchase Order. Commencement of
                    executions of this order deems that the supplier/subcontractor accepts the terms and conditions of
                    this order.
                  </p>
                </Col>
              </Row>

              <Row className="mt-1">
                <Col>
                  <div className="d-flex justify-content-between">
                    <div className="text-left">
                      <p>Prepared</p>
                      <p>
                        <b>Purchasing</b>
                      </p>
                      <p>___________________</p>
                    </div>
                    <div className="text-right">
                      <p>Approved by</p>
                      <p>
                        <b>Purchasing Manager</b>
                      </p>
                      <p>___________________</p>
                    </div>
                    <div className="text-right">
                      <p>Approved by</p>
                      <p>
                        <b>Director of Finance</b>
                      </p>
                      <p>___________________</p>
                    </div>
                  </div>
                </Col>
              </Row>

            </Container>
            <br />
            <br />
          </>
        ) : (
          <p>No data available.</p>
        )}
      </div>
      <Container style={{ paddingBottom: "20px" }}>
        <Row>
          <Col md={6}>
            {dataComparation.length > 0 ? (
              <>
                <p>Compare Prev Data</p>
                <Table size="sm" responsive striped bordered hover>
                  <thead>
                    <tr>
                      <th>#Item</th>
                      <th>Prev Pax</th>
                      <th>Curr Pax</th>
                      <th>dif Pax</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataComparation.map((item, index) => (
                      <tr key={index}>
                        <td>{item.item_name}</td>
                        <td>{item.prevPax}</td>
                        <td>{item.currentPax}</td>
                        <td style={{ color: "red" }}>{item.paxDifference}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <p>No Data Comparations</p>
            )}
          </Col>
          <Col md={6}>
            <Form.Group className="mb-2">
              <Form.Label size="sm">Additional cost</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                placeholder="Desc of cost   Ex:shipping cost or packaging cost"
                value={newCostDescription}
                onChange={(e) => setNewCostDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label size="sm">Price</Form.Label>
              <Form.Control
                size="sm"
                type="number"
                placeholder="Rp. 300.000"
                value={newCostAmount}
                onChange={(e) => setNewCostAmount(e.target.value)}
              />
            </Form.Group>
            <Button variant="secondary" size="sm" onClick={handleAddCost}>
              Add Cost
            </Button>
          </Col>
        </Row>
      </Container>
      <Container className="d-flex flow-row mt-1 justify-content-end" style={{ paddingBottom: "100px" }}>
        <Button
          variant="primary"
          type="button"
          className="ms-1"
          style={{ fontSize: "14px", display: "flex", alignItems: "center", justifyContent: "center" }}
          onClick={generatePDF}
        >
          Export PDF
        </Button>
      </Container>
      <Footer />
    </>
  );
};

export default PurchaseOrderByDate;
