import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Table, Button, Form, Spinner, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Dashboard.css";
import Header from "../components/Navbar";
import Footer from "../components/Footer";
import axios from "../utils/axios";
import moment from "moment";
import LOGO from "../assets/logo.png";
import Swal from "sweetalert2";
import { useHistory } from 'react-router-dom';

const Dashboard = () => {
  const pdfContentRef = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalAddQty, setShowModalAddQty] = useState(false);
  const [dataModalQty, setDataModalQty] = useState({ CB1: [], CB2: [] });
  const [modalContent, setModalContent] = useState([]);
  const [modalQtyMeal, setModalQtyMeal] = useState(false);
  const [detailQtyMeal, setDetailQtyMeal] = useState([]);

  const [showModalGeneratePO, setShowModalGeneratePO] = useState(false);
  const [listSupplier, setListSupplier] = useState([]);
  const today = moment().format("YYYY-MM-DD");
  const endDate = moment().add(7, "days").format("YYYY-MM-DD");
  const [startDateState, setStartDateState] = useState(today);
  const [endDateState, setEndDateState] = useState(endDate);

  const [result, setResult] = useState([]);
  const [statusNames, setStatusNames] = useState([]);
  const [selectedStatusName, setSelectedStatusName] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [pickHotel, setPickdHotel] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [queryResultMenuPO, setQueryResultMenuPO] = useState();
  
  const doOpenModalAddQty =  async (dateEvent,idBanquet,idHotel) => {
    try {
      const response = await axios.get(`/api/get/detail/menu/pax/${dateEvent}/${idBanquet}/${idHotel}`);
      console.log("response data: ", response.data);
      setDataModalQty(response.data)
      setShowModalAddQty(true);
    } catch (err) {
      console.error("Error find banquet forecast:", err);
      // setModalContent("Error loading data.");
    }
  }

  const handlePaxChange = (e, cbType, index) => {
    const { name, value } = e.target;
    setDataModalQty(prevState => {
      const newData = { ...prevState };
      newData[cbType][index]['Pax'] = value;
      return newData;
    });
  };

  const handleSubmitPax = async () => {
   
    try {
      const response = await axios.post('/api/updateinsert/menu/pax', dataModalQty); // Adjust the API endpoint as needed
      console.log(response.data);
      setShowModalAddQty(false);
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Data has been updated successfully'
      });
      // Add any additional logic after a successful update
    } catch (err) {
      console.error("Error updating banquet forecast:", err);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to update data'
      });
    }
  };

  // Main#
  const handleSubmitGeneratePO = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`/api/calculate/po/generate/${pickHotel}/${selectedSupplier}/${eventDate}`);
      console.log("response data: ", response.data);
  
      // // Filter lastData to include only items where totalPax > 0
      const filteredLastData = response.data.lastData.filter(item => item.totalPax > 0);
      // // Update the queryResultMenuPO state with the filtered data
      setQueryResultMenuPO({
        ...response.data,
        lastData: filteredLastData,
      });
  
    } catch (err) {
      console.error("Error find banquet forecast:", err);
    }
  };

  const formatToRupiah = (number) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0
    }).format(number);
  };

  const handleClick = (event) => {
    setSelectedHotel(event.target.value);
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setLoading(true);
    console.log("Search clicked with start date:", startDateState, "and end date:", endDateState);
    try {
      const response = await axios.get(`/api/get/banquet/forecast/${selectedHotel}/${startDateState}/${endDateState}`);
      console.log("response data list: ", response.data);
      
      setResult(response.data);
      const uniqueStatusNames = [...new Set(response.data.map((item) => item.StatusName))];
      setStatusNames(uniqueStatusNames);
    } catch (err) {
      console.error("Error find banquet forecast:", err);
      setResult([]);
    } finally {
      setLoading(false);
    }
  };

  const openModalMenu = async (dateEvent, timeStart, idHotel) => {
    setShowModal(true);
    // console.log(dateEvent);
    try {
      const response = await axios.get(`/api/get/detail/menu/${dateEvent}/${timeStart}/${idHotel}`);
      // console.log("response data: ", response.data);
      setModalContent(response.data);
    } catch (err) {
      console.error("Error find banquet forecast:", err);
      setModalContent("Error loading data.");
    }
  };

  const calculateCoffeeBreakPax = (meals) => {
    return meals
      .filter(
        (meal) =>
          meal.Package === "COFFEE BREAK" ||
          meal.Package === "COFFEE BREAK II" ||
          meal.Package === "CB I" ||
          meal.Package === "CB II"
      )
      .reduce((total, meal) => total + parseInt(meal.Pax, 10), 0);
  };

  const handleStatusChange = (event) => {
    setSelectedStatusName(event.target.value);
  };

  const sortedResult = selectedStatusName ? result.filter((item) => item.StatusName === selectedStatusName): result;

  const doPreviewPO = async (hotelID, banquetID, eventDate) => {
    history.push(`/purchase/order/${hotelID}/${banquetID}/${eventDate}`);
  };

  const openModalPO = async () => {
    try {
      const response = await axios.get(`/api/get/supplier/list`);
      setListSupplier(response.data);
      setShowModalGeneratePO(true);
    } catch (err) {
      console.error("Error find banquet forecast:", err);
    }
  };

  const handleGeneratePOBasedOnDate = () => {
    history.push(`/purchase/order/bydate/${pickHotel}/${selectedSupplier}/${eventDate}`)
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "Tentative":
        return "table-warning";
      case "Confirm":
        return "table-success";
      default:
        return "";
    }
  };

  const sortedResultsByDate = [...sortedResult].sort(
    (a, b) => new Date(a.onDate) - new Date(b.onDate)
  );

  const openModalMeal = (mealQty) => {
    console.log(mealQty)
    setDetailQtyMeal(mealQty)
    setModalQtyMeal(true)
  }

  const doUpdatePax = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/api/update/pax/meal`,{detailQtyMeal});
    
       // If the request was successful
       Swal.fire({
        title: 'Success!',
        text: 'The meal details have been updated successfully.',
        icon: 'success',
        confirmButtonText: 'OK'
      });

      // Close the modal
      setModalQtyMeal(false);

    } catch (err) {
      console.error("Error find banquet forecast:", err);

      // If there was an error with the request
      Swal.fire({
        title: 'Error!',
        text: 'There was an error updating the meal details. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK'
      });


    } finally {
        setDetailQtyMeal([]);
        setModalQtyMeal(false);
        window.location.reload();
    }


  };

  const testInsoft1 = async () => {
    try {
      const response = await axios.get(`/api/get/insoft/1`);
      console.log("response data insoft: ", response.data);
     
    } catch (err) {
      console.error("Error find banquet forecast:", err);
      
    } finally {
      console.log(false);
    }
  }

  const testInsoft2 = async () => {
    try {
      const response = await axios.get(`/api/get/insoft/2`);
      console.log("response data insoft: ", response.data);
     
    } catch (err) {
      console.error("Error find banquet forecast:", err);
      
    } finally {
      console.log(false);
    }
  }
 

  useEffect(() => {
    
    const getDataBanquetfromDB = async () => {
      setLoading(true);
      console.log("StartDate:", startDateState, "& EndDate:", endDateState);
      try {
        const response = await axios.get(`/api/get/banquet/forecast/all/hotel`);
        console.log("response data banquet: ", response.data);
        setResult(response.data);
        const uniqueStatusNames = [...new Set(response.data.map((item) => item.StatusName))];
        setStatusNames(uniqueStatusNames);
      } catch (err) {
        console.error("Error find banquet forecast:", err);
      } finally {
        setLoading(false);
      }
    };
    getDataBanquetfromDB();
  }, []);


  return (
    <>
      <Header />
        <div className="container-fluid mt-5">
          <div className="container pl-5 pr-5">
            <Form onSubmit={handleSearch}>
              <Row>
                <Col md="2" className="mb-2">
                  <Form.Check
                    type="radio"
                    name="hotel"
                    value="1"
                    style={{ fontSize: "14px" }}
                    label="Ashley Wahid Hasyim"
                    checked={selectedHotel === "1"}
                    onChange={handleClick}
                  />
                </Col>
                <Col md="2" className="mb-2">
                  <Form.Check
                    type="radio"
                    name="hotel"
                    value="2"
                    style={{ fontSize: "14px" }}
                    label="Ashley Tanah Abang"
                    checked={selectedHotel === "2"}
                    onChange={handleClick}
                  />
                </Col>
                <Col md="2" className="mb-2">
                  <Form.Control
                    required
                    size="sm"
                    className="text-center"
                    placeholder="Start"
                    type="date"
                    value={startDateState}
                    onChange={(e) => setStartDateState(e.target.value)}
                  />
                </Col>
                <Col md="2" className="mb-2">
                  <Form.Control
                    required
                    size="sm"
                    type="date"
                    className="text-center"
                    value={endDateState}
                    onChange={(e) => setEndDateState(e.target.value)}
                  />
                </Col>
                {sortedResultsByDate.length > 0 && (
                  <Col md="2" className="mb-2">
                    <Form.Select
                      size="sm"
                      aria-label="Default select example"
                      onChange={handleStatusChange}
                    >
                      <option value="">Sort by</option>
                      {statusNames.map((status, index) => (
                        <option key={index} value={status}>
                          {status}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                )}
                <Col md="2" className="d-flex flex-row">
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn btn-sm me-2"
                    style={{
                      fontSize: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                  <i className="bi bi-search" style={{ marginRight: "5px" }}></i> 
                  </Button>
                  {!loading && sortedResultsByDate.length > 0 && (
                    <Button
                      variant="warning"
                      type="button"
                      className="ms-1 btn btn-sm"
                      style={{
                        fontSize: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={openModalPO}
                    >
                      <i className="bi bi-card-checklist" style={{ marginRight: "5px" }}></i> Purchase Order
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </div>
          <hr />
          {loading && (
            <div className="text-center mt-5">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          {!loading && sortedResultsByDate.length > 0 && (
            <div ref={pdfContentRef} className="container">
              <div className="text-center">
                <img src={LOGO} width={100} alt="Logo" onClick={()=>testInsoft1()}/>
              </div>
              <h5 className="text-center" onClick={()=>testInsoft2()}>Coffee Break Order</h5>
                <p className="text-center">
                  {selectedHotel === "1" && <span>Ashley Wahid Hasyim</span>}
                  {selectedHotel === "2" && <span>Ashley Tanah Abang</span>}
                </p>
                <p className="text-center">
                  From: {moment(startDateState).format("ll")} to{" "}
                  {moment(endDateState).format("ll")}
                </p>
              <Table responsive bordered style={{ marginBottom: "10%" }}>
                <thead>
                  <tr className="table-dark">
                    <th>Event No</th>
                    <th>Hotel</th>
                    <th>Event Date</th>
                    <th>Event Time</th>
                    <th>Customer</th>
                    <th>Event Type</th>
                    <th>Function Room</th>
                    <th>Setup</th>
                    <th>Set Pax</th>
                    <th>Salesman</th>
                    <th>Created</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedResultsByDate.map((row, index) => {
                    const totalCoffeeBreakPax = calculateCoffeeBreakPax(row.Meals);
                    return (
                      <React.Fragment key={index}>
                        <tr
                          onClick={()=>doOpenModalAddQty(moment(row.onDate).format("YYYY-MM-DD"),row.ID,row.id_hotel)}
                          className={getStatusClass(row.StatusName)}
                          style={{
                            fontWeight: "bold",
                            flexDirection: "row",
                            fontSize: "10px",
                            textAlign: "justify",
                            fontStyle: "italic",
                          }}
                        >
                          <td>{row.ID}</td>
                          <td>{row.hotel}</td>
                          <td>{moment(row.onDate).format("DD-MMM-YYYY")}</td>
                          <td>{row.TimeSpan}</td>
                          <td>{row.GuestName}</td>
                          <td>{row.Package}</td>
                          <td>{row.Function}</td>
                          <td>{row.Setup}</td>
                          <td>{row.SetPax}</td>
                          <td>{row.Salesman}</td>
                          <td>{moment(row.CreatedOn).format("DD-MMM-YYYY HH:mm")}</td>
                          <td>{row.StatusName}</td>
                        </tr>
                        <tr className="table-light">
                          <td colSpan="12" className="p-3">
                            <Table bordered hovered size="sm" >
                              <thead>
                                <tr className="table-light text-center">
                                  <th>Package</th>
                                  <th>Time From</th>
                                  <th>Time To</th>
                                  <th>Menu Item</th>
                                  <th>Remark</th>
                                  <th>Pax</th>
                                 
                                </tr>
                              </thead>
                              <tbody>
                                {row.Meals.map((meal, mealIndex) => (
                                  <tr key={mealIndex} className="table-light">
                                    <td>{meal.Package}</td>
                                    <td>{meal.TimeFrom}</td>
                                    <td>{meal.TimeTo}</td>
                                    <td className="text-center">
                                      <i className="bi bi-info-square" onClick={() => openModalMenu(row.onDate, meal.TimeFrom, row.id_hotel)} ></i>
                                    </td>
                                    <td>{meal.remark}</td>
                                    <td onClick={()=>openModalMeal(meal)}>{meal.Pax}</td>
                                  </tr>
                                ))}
                                <tr className="table-active" style={{fontSize: "14px",fontWeight: "bolder"}}>
                                  <td colSpan={5}>Total Pax</td>
                                  <td>{totalCoffeeBreakPax} Pax</td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </Table>
              <br />
            </div>
          )}
          {!loading && result.length === 0 && (
            <p className="text-center mt-3">
              No data found for the selected criteria or please choose another date
            </p>
          )}
        </div>
      <Footer />

      {/* Modal Add Qty Menu */}
      <> 
        <Modal show={showModalAddQty} onHide={() => setShowModalAddQty(false)}>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "13px" }}>Pax Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>

              {dataModalQty.CB1.map((item, index) => (
                <div key={index}>
                <Form.Group className="mb-2">
                  <Form.Label>CB1 -  ({item.TimeFrom} to {item.TimeTo})</Form.Label>
                  <Form.Control type="number" defaultValue={item.Pax} onChange={(e) => handlePaxChange(e, 'CB1', index)}/>
                </Form.Group>
                </div>
              ))}
            
              {dataModalQty.CB2.map((item, index) => (
                <div key={index}>
                  <Form.Group className="mb-2">
                  <Form.Label>CB2 -  ({item.TimeFrom} to {item.TimeTo})</Form.Label>
                  <Form.Control type="number" defaultValue={item.Pax} onChange={(e) => handlePaxChange(e, 'CB2', index)}/>
                </Form.Group>
                </div>
              ))}

            
          </Modal.Body>
          <Modal.Footer>
            
            <Button variant="secondary" onClick={() => setShowModalAddQty(false)}>
              Close
            </Button>

            <Button onClick={()=>handleSubmitPax()} variant="primary">
              Submit & Update 
            </Button>

          </Modal.Footer>
          
        </Modal>
      </>
      {/* End Modal Add Qty Menu */}

      {/* Modal Edit Qty Menu */}
      <>
        <Modal show={modalQtyMeal} onHide={() => setModalQtyMeal(false)}>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "13px" }}>Menu Details</Modal.Title>
          </Modal.Header>
          <Form onSubmit={(e)=>doUpdatePax(e)}>
          <Modal.Body>
            {/* {JSON.stringify(detailQtyMeal)} */}
              <Form.Group as={Row} className="mb-0" >
                <Form.Label column sm="2">
                  Coorporate
                </Form.Label>
                <Col sm="10">
                  <Form.Control plaintext readOnly defaultValue={detailQtyMeal.FunctionName} />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-0" >
                <Form.Label column sm="2">
                  Details
                </Form.Label>
                <Col sm="10">
                  <Form.Control plaintext readOnly defaultValue={`${detailQtyMeal.Location} | ${detailQtyMeal.TimeFrom} - ${detailQtyMeal.TimeTo}`} />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-1" >
                <Form.Label column sm="2">
                  Pax
                </Form.Label>
                <Col sm="10">
                  <Form.Control size="sm" type="number" value={detailQtyMeal.Pax} onChange={(e) => setDetailQtyMeal(detailQtyMeal => ({...detailQtyMeal,Pax: e.target.value}))}/>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" >
                <Form.Label column sm="2">
                  Remark
                </Form.Label>
                <Col sm="10">
                <Form.Control size="sm" value={detailQtyMeal.remark} as="textarea" rows={3} required onChange={(e) => setDetailQtyMeal(detailQtyMeal => ({...detailQtyMeal,remark: e.target.value}))}/>
                </Col>
              </Form.Group>

              <Modal.Footer>
                <Button type="submit" variant="primary">
                  Update
                </Button>
                <Button variant="secondary" onClick={() => setModalQtyMeal(false)}>
                  Close
                </Button>
              </Modal.Footer>

          </Modal.Body>
          </Form>
         
        </Modal>
      </>

      {/* Modal Menu List */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "13px" }}>Menu Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered hovered>
            <thead>
              <tr>
                <th>Item</th>
                <th>Group</th>
                <th>Schedule</th>
                <th>Unit</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {modalContent.length > 0 ? (
                modalContent.map((item, index) => (
                  <tr key={index}>
                    <td>{item.item_name}</td>
                    <td>{item.group_name}</td>
                    <td>{item.code}</td>
                    <td>{item.unit}</td>
                    <td>{formatToRupiah(item.price)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">No data available</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Pick Supplier */}
      <Modal show={showModalGeneratePO} onHide={() => setShowModalGeneratePO(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "13px" }}>Choose Supplier & Event Date to Generate PO</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitGeneratePO}>
          <Modal.Body>
            <Form.Group className="mb-2">
              <Form.Label size="sm">Hotel</Form.Label>
              <Form.Select size="sm" required value={pickHotel} onChange={(e) => setPickdHotel(e.target.value)}>
                <option>Select Hotel</option>
                <option value={1}>Ashley Wahid Hasyim</option>
                <option value={2}>Ashley Tanah Abang</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label size="sm">Supplier</Form.Label>
              <Form.Select size="sm" required value={selectedSupplier} onChange={(e) => setSelectedSupplier(e.target.value)}>
                <option>Select Supplier</option>
                {listSupplier.length > 0 ? (
                  listSupplier.map((x, index) => (
                    <option key={index} value={x.id_supplier}>{x.name_supplier}</option>
                  ))
                ) : (
                  <option>No Data Supplier</option>
                )}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label size="sm">Date Event</Form.Label>
              <Form.Control size="sm" type="date" placeholder="Date Event" required value={eventDate} onChange={(e) => setEventDate(e.target.value)} />
            </Form.Group>
         
            {/* <pre>{queryResultMenuPO}</pre> */}
            {queryResultMenuPO && queryResultMenuPO.lastData && queryResultMenuPO.lastData.length > 0 ? (
              <>
                <p>Last Data</p>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Menu</th>
                      <th>Item</th>
                      <th>Pax</th>
                    </tr>
                  </thead>
                  <tbody>
                    {queryResultMenuPO.lastData.map((item, index) => (
                      <tr key={index}>
                        <td>{moment(item.date_cal).format('LL')}</td>
                        <td>{item.group_name} ({item.code})</td>
                        <td>{item.item_name}</td>
                        <td>@{item.totalPax}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <center><p>*No Data order on selected days</p></center>
            )}

            {queryResultMenuPO && queryResultMenuPO.comparePax && queryResultMenuPO.comparePax.length > 0 && (
              <>
              <p>Compare Data</p>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#Item</th>
                    <th>Prev Pax</th>
                    <th>Curr Pax</th>
                    <th>dif Pax</th>
                  </tr>
                </thead>
                <tbody>
                {queryResultMenuPO.comparePax.map((item, index) => (
                  <tr key={index}>
                    <td>{item.item_name}</td>
                    <td>{item.prevPax}</td>
                    <td>{item.currentPax}</td>
                    <td style={{color:"red"}}>{item.paxDifference}</td>
                  </tr>
                ))}
                </tbody>
              </Table>
              </>
            )}

          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-sm" variant="secondary" onClick={() => setShowModalGeneratePO(false)}>
              Close
            </Button>
            <Button variant="info" className="btn btn-sm" type="submit">
            <i class="bi bi-calculator"></i> Calculate
            </Button>
            {queryResultMenuPO && queryResultMenuPO.lastData && queryResultMenuPO.lastData.length > 0 && (
              <Button className="btn-sm" variant="success" onClick={handleGeneratePOBasedOnDate}>
                <i className="bi bi-printer"></i> Generate
              </Button>
            )}
           
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );

};

export default Dashboard;
