import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import logo from '../assets/logo.png';
import axios from '../utils/axios';
import { getUser, removeUserSession } from '../utils/Common';
import { getUserRoleID } from '../utils/Common'; // Ensure the correct path is used
import moment from 'moment';

function Navigate({ runGetAPIInsoft }) {
  const history = useHistory();
  const [userDetail, setUserDetail] = useState([]);

  const handleLogout = async () => {
    removeUserSession();
    history.push('/');
  };

  const handleLoadAPI = () => {
    runGetAPIInsoft(); // Run the function passed from props
  };

  useEffect(() => {
    const userData = getUser();
    if (userData) {
      setUserDetail(userData);
    }

    const ROLEID = getUserRoleID(); // Get user ID
    if (ROLEID === 999) {
      history.push('/$2a$12$9S4H3D1Q7S9VWVYhxnfOjOEjKt9pIURhsrlagKUS4MRYt29h96x4O/supplier');
    }
  }, []);

  const openInNewTab = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div>
      <Navbar style={{ fontSize: '12px' }} bg="dark" data-bs-theme="dark" expand="sm" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand>
            <img width={50} src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link onClick={() => history.push('/dashboard')}>Home</Nav.Link>
              <Nav.Link onClick={() => openInNewTab('/po/list')}>Purchase Order</Nav.Link>
              <NavDropdown title="Menu" id="basic-nav-dropdown">
                <NavDropdown.Item style={{ fontSize: '12px' }} onClick={() => openInNewTab('/menu/list')}>List</NavDropdown.Item>
                <NavDropdown.Item style={{ fontSize: '12px' }} onClick={() => openInNewTab('/calendar/1')}>Ashley Wahid Hasyim</NavDropdown.Item>
                <NavDropdown.Item style={{ fontSize: '12px' }} onClick={() => openInNewTab('/calendar/2')}>Ashley Tanah Abang</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Supplier" id="basic-nav-dropdown">
                <NavDropdown.Item style={{ fontSize: '12px' }} onClick={() => openInNewTab('/supplier/list')}>List</NavDropdown.Item>
                <NavDropdown.Item style={{ fontSize: '12px' }} onClick={() => openInNewTab('/$2a$12$9S4H3D1Q7S9VWVYhxnfOjOEjKt9pIURhsrlagKUS4MRYt29h96x4O/supplier')}>Dashboard</NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav className="ml-auto">
              <NavDropdown title={`Signed in as: ${userDetail ? userDetail.name : ''}`} id="user-nav-dropdown">
                <NavDropdown.Item style={{ fontSize: '12px' }} onClick={() => openInNewTab('/profile')}>Profile</NavDropdown.Item>
                <NavDropdown.Item style={{ fontSize: '12px' }} onClick={handleLogout}>Logout</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Navigate;
