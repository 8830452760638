import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Container ,NavDropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import logo from '../assets/logo.png';
import { getUser, removeUserSession } from '../utils/Common';
import { getUserRoleID } from '../utils/Common'; // Ensure the correct path is used

function NavSupplier({ runGetAPIInsoft }) {
  const history = useHistory();
  const [userDetail, setUserDetail] = useState([]);
  const inactivityTimer = useRef(null);

  const handleLogout = async () => {
    removeUserSession();
    history.push('/');
  };

  const resetInactivityTimer = () => {
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }
    inactivityTimer.current = setTimeout(handleLogout, 30 * 60 * 1000); // 30 minutes
  };

  const handleUserActivity = () => {
    resetInactivityTimer();
  };

  useEffect(() => {
    const userData = getUser();
    if (userData) {
      setUserDetail(userData);
    }

    const ROLEID = getUserRoleID(); // Get user ID
    if (ROLEID !== 999) {
      history.push('/');
    }

    // Reset inactivity timer on any user activity
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);

    // Set the initial inactivity timer
    resetInactivityTimer();

    return () => {
      // Cleanup event listeners and timer on component unmount
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current);
      }
    };
  }, []);

  return (
    <div>
      <Navbar style={{ fontSize: '12px' }} bg="dark" data-bs-theme="dark" expand="sm" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand>
            <img width={50} src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link onClick={() => history.push('/$2a$12$9S4H3D1Q7S9VWVYhxnfOjOEjKt9pIURhsrlagKUS4MRYt29h96x4O/supplier')}>Home</Nav.Link>
              <Nav.Link onClick={() => history.push('/po/list/$2a$12$9S4H3D1Q7S9VWVYhxnfOjOEjKt9pIURhsrlagKUS4MRYx9h96x4O')}>Purchase Order</Nav.Link>
            </Nav>
            <Nav className="ml-auto">
              <NavDropdown title={`Signed in as: ${userDetail ? userDetail.name : ''}`} id="user-nav-dropdown">
               
                <NavDropdown.Item style={{ fontSize: '12px' }} onClick={handleLogout}>Logout</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavSupplier;
